import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosBaseURL from './httpCommon'; // Import your axios instance

import './BoltHeader.css'; // Import your CSS file for styling

// import logo from './images/Bolt_IoT_Final_Logo.png';
import logo from './images/Bolt_logo_2.png';
import profile_img from './images/new_user_profile_icon_img.png';

const BoltHeader = ({ salutation, name, isFacultyDashboard, showApiKey, email}) => {
  console.log("show api key:",showApiKey);
  const [isProfileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const navigate = useNavigate(); 

  const handleLogoClick = () => {
    // window.location.reload();
    navigate('/home');
  };

  const handleProfileClick = () => {
    setProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const handleLogout = () => {
    // Assuming you're sending a POST request to the login URL
    axiosBaseURL.get('/logout')
      .then(response => {
        // Handle the response if needed
        if (response === "200") {
            navigate('/');  // Redirect to the desired route
        }
      })
      .catch(error => {
        // Handle errors
        console.error('Error during logout:', error);
        navigate('/');  // Redirect to the desired route
      });
  };

  return (
    <header className="page-header">
      <div className="left-content">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>
      <div className="right-content">
          <div className="profile-and-name">
          
          <div className="greeting-msg">{email}</div>
          <img
            src={profile_img}
            alt="Profile"
            className="profile-icon"
            onClick={handleProfileClick}
          />
          <div className="profile-dropdown">
          </div>
          {isProfileDropdownOpen && (
              <div className="dropdown-menu">
              <ul>
              {showApiKey && ( <li onClick={() => navigate("/ApiUsage")}>API Key</li>)}
                <li onClick={() => window.open("https://boltiot.kampsite.co/", "_blank")} rel="noopener noreferrer">FEATURE REQUEST</li>
                <li onClick={handleLogout}> LOGOUT</li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default BoltHeader;
